import React, { useContext, useEffect, useState } from "react";
import PermissionsWrapper from "common-components/permissions-wrapper/permissions-wrapper";
import { PermissionsContext } from "global/permissions";
import "./SubmissionSubmitPage.scss";
import SubmissionFormSubmit from "components/submission-forms/submit/SubmissionFormSubmit";
import pages from "pages/pages";
import { useSubmissionLocation } from "global/useSubmissionLocation";
import { useMutation } from "@tanstack/react-query";
import { submitSubmission as submitSubmissionApi } from "api";
import { FormMode } from "components/submission-forms/common/field-container/SubmissionFieldContainer";
import SubmissionFormSubmittedMessage from "components/submission-forms/submit/submitted-message/SubmissionFormSubmittedMessage";
import { useNavigate } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { UserClaims } from "@okta/okta-auth-js";
import { useStatusManager } from "global/use-status-manager";

const namespace = "curation-submission-submit-page";

export default function SubmissionSubmitPage(): JSX.Element {
  const perms = useContext(PermissionsContext).submissions.view;
  const {
    data: submission,
    refetch,
    status: getterStatus
  } = useSubmissionLocation(pages.submission.path);
  const { status: submitStatus, mutate: submitSubmission } = useMutation(
    submitSubmissionApi,
    {
      onMutate: () => {
        overrideStatus("onSubmit", "loading");
      },
      onSuccess: () => {
        refetch().then(() => {
          overrideStatus("onSubmit", undefined);
        });
      }
    }
  );
  const { status, overrideStatus } = useStatusManager(
    getterStatus,
    submitStatus
  );

  const [formMode, setFormMode] = useState<FormMode>("submitted");
  const navigate = useNavigate();

  const { oktaAuth } = useOktaAuth();
  const [user, setUser] = useState<UserClaims>();
  useEffect(() => {
    oktaAuth.getUser().then(u => setUser(u));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!submission || !user) {
      return;
    }

    if (submission.assignee.email !== user.email) {
      navigate(pages.unauthorized.go());
    } else if (submission.status.status === "submitted") {
      setFormMode("submitted");
    } else if (submission.status.status === "created") {
      setFormMode("submit");
    } else if (
      submission.status.status === "approved" ||
      submission.status.status === "rejected"
    ) {
      navigate(pages.submission.view.go(submission.id));
    } else if (submission.status.status === "cancelled") {
      navigate(pages.submission.cancelled.go(submission.id));
    } else if (submission.status.status === "processed") {
      navigate(pages.submission.processed.go(submission.id));
    } else if (submission.status.status === "in review") {
      navigate(pages.submission.pendingReview.go(submission.id));
    }
  }, [submission, navigate, user]);

  return (
    <div className={namespace}>
      <PermissionsWrapper permissions={[perms, !!submission, !!user]}>
        <SubmissionFormSubmittedMessage
          formMode={formMode}
          submittedDate={submission?.submittedDate}
          onEditClick={() => setFormMode("resubmit")}
          skipApproval={submission?.form.skipApproval}
          workstream={submission?.form.workstream.workstream}
        />
        <SubmissionFormSubmit
          formMode={formMode}
          setFormMode={setFormMode}
          submission={submission}
          onSubmit={submitSubmission}
          status={status}
        />
      </PermissionsWrapper>
    </div>
  );
}
