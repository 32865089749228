import React from "react";
import "./SubmissionFormReviewRadioButtons.scss";
import { Radio } from "@gitlab-rtsensing/component-library";

const namespace = "curation-submission-form-review-radio-buttons";

export type ReviewMode = "edit" | "accept" | "reject";

type Props = {
  reviewMode: ReviewMode | undefined;
  onChange: (reviewMode: ReviewMode) => void;
  isDisabled: boolean;
};

export const SubmissionFormReviewRadioButtons = (props: Props) => {
  return (
    <div className={namespace}>
      <div className={`${namespace}-buttons-container`}>
        <Radio
          variant="primary"
          value="Edit Submission"
          checked={props.reviewMode === "edit"}
          onChange={() => {
            if (!props.isDisabled) {
              props.onChange("edit");
            }
          }}
          disabled={props.isDisabled} // doesn't actually work, but might in future version, and change styling
        />
        <Radio
          variant="primary"
          value="Accept Submission"
          checked={props.reviewMode === "accept"}
          onChange={() => {
            if (!props.isDisabled) {
              props.onChange("accept");
            }
          }}
          disabled={props.isDisabled} // doesn't actually work, but might in future version, and change styling
        />
        <Radio
          variant="primary"
          value="Reject Submission"
          checked={props.reviewMode === "reject"}
          onChange={() => {
            if (!props.isDisabled) {
              props.onChange("reject");
            }
          }}
          disabled={props.isDisabled} // doesn't actually work, but might in future version, and change styling
        />
      </div>
    </div>
  );
};

export default SubmissionFormReviewRadioButtons;
